import axios from "axios";
import Cookies from "js-cookie";

const URL = process.env.VUE_APP_ZIDAY_API;

export default {
    getAdquisitions(offset: number) {
        const token = Cookies.get("keycloakToken");
        return axios.get(URL + "/safety/adquisition?offset=" + offset, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    getOilSpills(adquisitionId: string, offset: number) {
        const token = Cookies.get("keycloakToken");
        return axios.get(URL + "/safety/adquisition/" + adquisitionId + "/oil-spill?state=OilSpillDetected&offset=" + offset, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    getAllOilSpills(offset: number) {
        const token = Cookies.get("keycloakToken");
        return axios.get(URL + "/safety/oil-spill?state=OilSpillDetected&offset=" + offset, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    getWastewaters(adquisitionId: string, offset: number) {
        const token = Cookies.get("keycloakToken");
        return axios.get(URL + "/safety/adquisition/" + adquisitionId + "/wastewater?state=WastewaterDetected&offset=" + offset, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    getAllWastewaters(offset: number) {
        const token = Cookies.get("keycloakToken");
        return axios.get(URL + "/safety/wastewater?state=WastewaterDetected&offset=" + offset, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    acknowledgeOilSpill(adquisitionId: string, oilSpillId: string) {
        const token = Cookies.get("keycloakToken");
        return axios.post(URL + "/safety/adquisition/" + adquisitionId + "/oil-spill/" + oilSpillId + "/ack", {}, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    acknowledgeWastewater(adquisitionId: string, wastewaterId: string) {
        const token = Cookies.get("keycloakToken");
        return axios.post(URL + "/safety/adquisition/" + adquisitionId + "/wastewater/" + wastewaterId + "/ack", {}, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    } 
}