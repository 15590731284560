export class amuraEvent {
  public id: string;
  public version: string;
  public timestamp: Date;
  public reportedAt: Date;
  public reportedBy: Date;
  public eventType: string;
  public event: string;
  public additionalData: Object;
  public vendorExtension: Object;

  constructor(
    id: string,
    version: string,
    timestamp: Date,
    reportedAt: Date,
    reportedBy: Date,
    eventType: string,
    event: string,
    additionalData: Object,
    vendorExtension: Object
  ) {
    this.id = id;
    this.version = version;
    this.timestamp = timestamp;
    this.reportedAt = reportedAt;
    this.reportedBy = reportedBy;
    this.eventType = eventType;
    this.event = event;
    this.additionalData = additionalData;
    this.vendorExtension = vendorExtension;
  }
}
