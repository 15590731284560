import axios from "axios";
import Cookies from "js-cookie";

const querystring = require("querystring");

const URL = process.env.VUE_APP_KEYCLOAK_URL;
const CLIENT_ID = process.env.VUE_APP_KEYCLOAK_CLIENT_ID;
const CLIENT_SECRET = process.env.VUE_APP_KEYCLOAK_CLIENT_SECRET;
const USER = process.env.VUE_APP_KEYCLOAK_USERNAME;
const PASSWORD = process.env.VUE_APP_KEYCLOAK_PASSWORD;

export default {
    login() {
        axios.post(
            URL,
            querystring.stringify({
                grant_type: "password",
                client_id: CLIENT_ID,
                client_secret: CLIENT_SECRET,
                username: USER,
                password: PASSWORD,
            }),
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        )
        .then((response) => {
            Cookies.set("keycloakToken", response.data.access_token, { expires: 365 });
        })
        .catch((error) => {
            console.log(error);
        });

    },
    refresh() {
        this.login();
    }

}